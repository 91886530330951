<template>
  <section>
    <top-alternative-section
      :image="image"
      :title="$t('contacto.title')"
    ></top-alternative-section>

    <contact-section></contact-section>

    <map-section
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.6578766033376!2d-99.16330882539822!3d19.42718268185112!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ff33c23c67d1%3A0x2364c6c3438cb505!2sAv.%20Insurgentes%20Sur%2064%2C%20Ju%C3%A1rez%2C%20Cuauht%C3%A9moc%2C%2006600%20Cuauht%C3%A9moc%2C%20CDMX!5e0!3m2!1sen!2smx!4v1734459799211!5m2!1sen!2smx"
    ></map-section>
  </section>
</template>

<script>
import TopAlternativeSection from '../sections/TopAlternativeSection.vue';
import ContactSection from "../sections/ContactSection.vue";
import MapSection from "../sections/MapSection.vue";
import ContactImage from "../../assets/contacto.jpg";

export default {
  components: {
    TopAlternativeSection,
    ContactSection,
    MapSection
  },
  data(){
    return{
      image: ContactImage,
    }
  }
}
</script>