<template>
  <base-section
    id="map"
    space="0"
  ><iframe
      allowfullscreen
      aria-hidden="false"
      frameborder="0"
      height="500"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1881.3301448591974!2d-99.16222254735439!3d19.427078494449614!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ff33c23c67d1%3A0x2364c6c3438cb505!2sAv.%20Insurgentes%20Sur%2064%2C%20Ju%C3%A1rez%2C%20Cuauht%C3%A9moc%2C%2006600%20Cuauht%C3%A9moc%2C%20CDMX!5e0!3m2!1sen!2smx!4v1734373923489!5m2!1sen!2smx"
      style="border:0; display: block;"
      tabindex="0"
      width="100%"
    />
  </base-section>
</template>

<script>
  export default {
    provide: {
      heading: { align: 'center' },
    },
  }
</script>
